<template>
  <InitLayout>
    <!-- logo placeholder -->
    <b-container class="align">
    <b-row class="justify-content-center" no-gutters>
      <b-img
        src="@/assets/personalPac-log.svg"
        class="px-0 w-auto"
        height="50px"
        alt="Personal PAC"
      ></b-img>
    </b-row>
    <b-row class="justify-content-center">
        <span class="errorCode">404</span>
    </b-row>
    <b-row class="justify-content-center">
        <span class="error">Page not found</span>
    </b-row>
    <b-row class="justify-content-center pt-3">
        <span class="errorDis">Whoops...We can't seem to find the page you are looking for.</span>
    </b-row>
    </b-container>  
  </InitLayout>
</template>

<script>
// services

// components
// @ is an alias to /src
import InitLayout from "@/layout/InitLayout";

export default {
  name: "Error404",
  components: {
    InitLayout,
  },
  data() {
    return {
      isAPILoading: false,
    };
  },
  watch: {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.errorCode {
    width: 193px;
    height: 110px;
    
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
    text-align: center;
    color: #3C3B6E;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.error {
    width: 374px;
    height: 47px;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #3C3B6E;
    flex: none;
    order: 2;
    flex-grow: 0;
}
.errorDis {
    width: 367px;
    height: 68px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #9FA2B4;
    flex: none;
    order: 3;
    flex-grow: 0;
}
.align{
    margin-left: 75%;
    margin-right: 25%;
    padding-top: 20%;
}
@media (max-width: 991.98px) {
  .align {
    margin-left: 50%;
    margin-right: 50%;
    padding-top: 20%;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .align {
    margin-left: 50%;
    margin-right: 50%;
    padding-top: 20%;
  }
}


</style>
