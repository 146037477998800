<template>
  <InitLayout>
    <!-- logo placeholder -->
    <b-container>
      <b-row class="justify-content-center" no-gutters>
        <b-img
          src="@/assets/giveProudly-log.svg"
          class="px-0 w-auto"
          height="80px"
          alt="Give Proudly"
        ></b-img>
      </b-row>
      <b-row class="justify-content-center">
        <span class="errorCode">404</span>
      </b-row>
      <b-row class="justify-content-center">
        <span class="error">Page not found</span>
      </b-row>
      <b-row class="justify-content-center pt-3">
        <span class="errorDis text-wrap"
          >Whoops...We can't seem to find the page you are looking for.</span
        >
      </b-row>
    </b-container>
  </InitLayout>
</template>

<script>
// services

// components
// @ is an alias to /src
import InitLayout from "@/layout/give-proudly/InitLayout";

export default {
  name: "Error404",
  components: {
    InitLayout,
  },
  data() {
    return {
      isAPILoading: false,
    };
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.errorCode {
  width: 193px;
  height: 110px;

  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 112px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.error {
  width: 374px;
  height: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 2;
  flex-grow: 0;
}
.errorDis {
  width: 300px;
  height: 68px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #9fa2b4;
  flex: none;
  order: 3;
  flex-grow: 0;
}
</style>
